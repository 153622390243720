@import '../assets/style/mixins.scss';
@import '../assets/style/variables.scss';

.chapter {
  @include page;
}
.returnLink {
  @include text($fzMedium);
}
.header {
  margin: 0 auto;
  max-width: $widthText;
  h1 {
    margin: 0;
    padding: 0;
    margin-top: $step;
  }
  p {
    margin: 0;
    padding: 0;
    padding: calc($step / 4);
  }
}
.title {
  @include text($fzHuge);
  font-weight: 500;
  padding: calc($step / 4);
}
.link {
  padding: calc($step / 4);
}
.text {
  @include text($fzMedium);
  margin-top: $step;
  line-height: 1.25em;
}
