@import '../assets/style/mixins.scss';
@import '../assets/style/variables.scss';

.stories {
  @include page;
}

.booksList {
  @include flexCenter;
  flex-wrap: wrap;
}

.book {
  max-width: 320px;
  padding: $step;
  text-align: center;
}

.cover,
.year,
.genre {
  display: block;
}

.link {
  text-align: center;
}
.cover {
  max-width: 280px;
  margin-bottom: calc($step / 2);
  display: inline-block;
}
.title {
  @include text($fzLarge);
  font-weight: 700;
  margin-bottom: 15px;
}
.genre {
  @include text($fzMedium);
}
.year {
  @include text($fzLarge);
  margin-bottom: calc($step / 2);
}
