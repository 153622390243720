@import '../assets/style/mixins.scss';
@import '../assets/style/variables.scss';

.chapters {
  @include page;
}
.returnLink {
  @include text($fzMedium);
}
.storyInfo {
  display: grid;
  grid-template-columns: 220px 1fr;
  max-width: $widthBlock;
  margin: 0 auto;
  margin-bottom: calc($step * 2);
  img {
    width: 200px;
  }
  h1,
  p {
    margin: 0;
    padding: 0;
  }
  h1 {
    @include text(48px);
    margin-bottom: $step;
  }
  .year {
    margin-bottom: $step;
  }
  .summary {
    font-style: italic;
  }
}

@include mobile {
  .storyInfo {
    display: block;
  }
}

.title {
  @include text($fzHuge);
  font-weight: 300;
}
.linksList {
  margin: 0;
  padding: 0;
  list-style: none;
  .listItem {
    padding-bottom: calc($step / 2);
    padding-top: calc($step / 2);
    @include text($fzMedium);
    .link {
      @include text($fzLarge);
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
}
