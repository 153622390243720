@import './assets/style/mixins.scss';
@import './assets/style/variables.scss';

.app {
  width: 100%;
}

.view {
  display: flex;
}
