@import '../assets/style/mixins.scss';
@import '../assets/style/variables.scss';

.block {
  margin-bottom: calc($step / 2);
  text-align: justify;
  cursor: pointer;
}

.blockSelected {
  background: $colorBgHover;
}

.lineNumber {
  position: relative;
  right: 60px;
  color: #0075;
}

.text {
  max-width: $widthText;
  margin: 0 auto;
}

.review {
  margin-top: $step;
  text-align: center;
  margin-bottom: $step;
}

.icon {
  margin-right: calc($step / 2);
}

.commentForm {
  margin-top: calc($step / 2);
  padding-bottom: calc($step / 2);
  button {
    padding: calc($step / 2) $step;
    border: none;
    margin-right: calc($step / 2);
    @include text($fzMedium);
  }
}
.commentFormNeutral {
  button {
    background: #7f9efb;
  }
}
.commentFormGreen {
  button {
    background: limegreen;
  }
}
.commentFormRed {
  button {
    background: salmon;
  }
}
.commentTextarea {
  width: calc(95%);
  height: 100px;
  border: none;
  margin-bottom: calc($step / 2);
  @include text($fzMedium);
}
