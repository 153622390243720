@import 'variables';

@mixin page {
  @include padding;
  background-color: $colorBg;
  min-width: 100px;
  max-width: $widthBlock;
  border-radius: $borderRadius;
  margin: 0 auto;
}

@mixin padding {
  padding: $step;
}

@mixin header {
  margin: 0;
  font-size: 36px;
  font-weight: normal;
  margin-bottom: $step;
}

@mixin text($fontSize: 20px) {
  color: $colorText;
  font-size: $fontSize;
}

@mixin clearPaddings {
  margin: 0;
  padding: 0;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
}

@mixin margin {
  margin: $step;
}

@mixin marginHalf {
  margin: $step/2;
}

@mixin hoverColor {
  &:hover {
    background: $colorBgHover;
  }
}

@mixin bgBlue {
  background-image: url('../img/bg.jpg');
  background-size: 300px;
  background-attachment: fixed;
}

@mixin tablet {
  @media (max-width: $desktopMediaWidth - 0.1) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $tabletMediaWidth - 0.1) {
    @content;
  }
}
