@import '../assets/style/mixins.scss';
@import '../assets/style/variables.scss';

.header {
  margin-bottom: $step;
  text-align: center;
  background: #0005;
  padding: $step;
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .description {
    color: white;
    margin-top: 10px;
  }
  img {
    margin-right: calc($step / 2);
    display: inline-block;
    width: 320px;
  }
  h1 {
    @include text(46px);
    color: white;
    margin: 0;
    font-weight: 500;
    display: inline-block;
  }
  p {
    @include text(24px);
    margin: 0;
    padding: 0;
    color: white;
    .spanButton {
      color: yellow;
      cursor: pointer;
    }
  }

  .form {
    background: $colorBgHover;
    width: auto;
    display: inline-block;
    margin: 0 auto;
    margin-top: $step;
    padding: 20px;
    .inputBlock {
      display: block;
      margin: 0 auto;
      text-align: left;
      padding: 10px;
    }
    label {
      display: block;
      @include text(24px);
      margin-bottom: 5px;
    }
    input {
      padding: 10px;
      border: none;
    }
    button {
      margin-top: $step;
      border: none;
      background: royalblue;
      padding: 10px 20px;
      @include text(24px);
      color: white;
    }
  }
}
