$width: 1400px;
$step: 20px;
$borderRadius: 5px;

$colorBody: #77d;
$colorText: #111;
$colorBg: #eef;
$colorBgHover: #ddf;

$fzSmall: 12px;
$fzMedium: 20px;
$fzLarge: 24px;
$fzHuge: 36px;

$widthText: 640px;
$widthBlock: 1200px;

$desktopMediaWidth: 1440px;
$tabletMediaWidth: 991px;
