@import "../assets/style/mixins.scss";
@import "../assets/style/variables.scss";

.notFound {
  @include padding;
  width: $width;
  background: $colorBg;
  border-radius: $borderRadius;
  text-align: center;
  h1 {
    font-size: 80px;
    margin: 0;
    padding: 0;
    font-weight: 300;
  }
  p {
    font-size: $fzMedium;
    margin: 0;
    padding: 0;
  }
}
